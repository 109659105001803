.reviews-list-wrap
  width: 100%

.reviews-list
  width: 100%
  min-height: calc(100vh - 162px)
  background-color: #fff
  
.reviews-item
  width: 100%
  box-sizing: border-box
  display: flex
  position: relative
  flex-direction: row
  align-items: center
  padding: 20px
  border-bottom: 1px solid #dbdbdb
  &:last-child
    border-bottom: none
  .reviews-image
    width: 140px
    height: 140px
    border-radius: 5px
    flex-shrink: 0
    background-size: 100%
    background-position: center
  .reviews-info
    position: relative
    width: calc(100% - 150px)
    height: 140px
    margin-left: 10px
    .reviews-name
      font-size: 16px
      font-weight: 800
      margin-bottom: 10px
      width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .review-author
      font-size: 14px
      font-weight: 400
      margin-bottom: 10px
      color: #555
    .review-content
      font-size: 14px
      font-weight: 400
      margin-bottom: 10px
      color: #333

.reviews-bottom-area
  position: absolute
  bottom: 0px
  width: 100%

.no-product
  color: #b1b1bc
  position: absolute
  width: 100%
  text-align: center
  top: 50%
  transform: translateY(-50%)
  img
    margin-bottom: 20px
