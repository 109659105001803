.module-assemble-view-wrap
  padding-top: 62px
  background-color: #e0e0e0
  position: relative
  height: calc(var(--vh, 1vh) * 100)
  box-sizing: border-box
  .side-btn-area
    display: flex
    flex-direction: column
    position: absolute
    right: 0
    top: 82px
    z-index: 9
    button
      display: flex
      box-sizing: border-box
      width: 70px
      height: 70px
      border: 0
      flex-direction: column
      justify-content: center
      align-items: center
      outline: none
      cursor: pointer
      span
        font-size: 12px
        font-family: 'Noto Sans KR', sans-serif
        font-weight: 700
      &.btn-similar-product
        background-color: #3D3D57
        color: #ffffff
      &.btn-3d-mode
        background-color: #f0f0f4
        color: #3D3D57
      &.btn-back
        border-radius: 50%
        width: 56px
        height: 56px
        margin: 10px auto
        background-color: #afafb7
        span
          position: relative
          top: -5px



.module-recommend-item-list
  .item
    width: 120px
    height: 120px
    border: 1px solid #dbdbdb
    background-size: 102px auto
    background-position: center
    background-repeat: no-repeat
    margin-right: 10px

  .module-image
    width: 120px
    height: 120px
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden

  .module-image img
    width: 100%
    height: 100%
    object-fit: contain

.recommend-wrap
  position: fixed
  padding: 20px
  width: 384px
  left: 50%
  bottom: -99px
  background-color: #ffffff
  box-sizing: border-box
  transition: bottom 0.3s
  @media screen and (max-width: 768px)
    width: 100%
    left: 0
  &.show
    bottom: 70px
  .module-recommend-title-area
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 10px
    h2
      font-size: 16px
      color: #3D3D57
      font-weight: 700
    button
      display: flex
      cursor: pointer
      user-select: none
      align-items: center
      justify-content: center
      background-color: transparent
      border: 0
      padding: 0
      span
        margin-right: 5px
        font-size: 12px
        font-weight: 700
        color: #909090
  .expand-btn
    position: absolute
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    left: 0
    top: -37px
    border-radius: 0 10px 0 0
    padding: 10px 20px
    background-color: #fff
    span
      margin-right: 10px
      font-size: 13px
      color: #3D3D57
      font-weight: 700
    img
      transition: transform 0.3s
      transform: rotate(-90deg)
    &.show
      img
        transform: rotate(90deg)
.bottom-button-area
  padding: 20px
  background-color: #ffffff
  position: absolute
  box-sizing: border-box
  width: 100%
  display: flex
  left: 0
  bottom: 0
  button
    font-size: 16px
    font-weight: 700
    font-family: "Noto Sans KR", sans-serif
    padding: 0
    border: 0
    height: 50px
    border-radius: 5px
    &:first-child
      margin-right: 10px
      flex: 3
      color: #ffffff
      background-color: #3D3D57
    &:last-child
      color: #54585a
      background-color: #f5f6f8
      flex: 1
  @media screen and (max-width: 768px)
    width: 100%
    left: 0

.module-assemble-3d-area
  position: relative
  margin-top: 0px
  height: calc(var(--vh, 1vh) * 81)
  .react-colorful
    &.picker
      position: absolute
      left: 20px
      top: 20px
      width: 100px
      height: 100px

.furnitureview-list
  position: absolute
  top: 140px
  left: 20px

#leva__root > *
    scale: 20%
