
.product-option-popup-wrap
  width: 384px
  position: fixed
  top: 100vh
  height: calc(var(--vh, 1vh) * 100)
  background-color: #00000077
  transition: top 0.2s
  &.open
      top: 0
  @media screen and (max-width: 767px)
    width: 100%
  z-index: 999999
  .product-option-popup
    position: absolute
    bottom: 0
    box-sizing: border-box
    width: 100%
    background-color: #fff
    padding: 20px
    .product-option-popup-close-btn
      position: absolute
      right: 20px
      top: 20px
      background-color: transparent
      padding: 0
      border: 0
      cursor: pointer

.product-size-change, .product-color-change, .product-texture-change
  h2
    font-weight: 700
    margin-bottom: 15px
  margin-bottom: 20px

.size-change-btn, .color-change-btn, .texture-change-btn
  user-select: none
  cursor: pointer
  display: flex
  justify-content: space-between
  align-items: center
  box-sizing: border-box
  width: 100%
  padding: 15px
  border: 1px solid #dbdbdb
  border-radius: 5px
  color: #696969
  font-weight: 500
  img
    transform: rotate(90deg)
  &.open
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    img
      transform: rotate(-90deg)

.size-option-list, .color-option-list, .texture-option-list
  display: none
  border: 1px solid #dbdbdb
  border-top: 0
  border-radius: 0 0 5px 5px
  &.open
    display: block
  .size-option, .color-option, .texture-option
    cursor: pointer
    user-select: none
    display: flex
    justify-content: space-between
    padding: 15px
    border-bottom: 1px solid #dbdbdb
    &:last-child
      border-bottom: 0
    span
      font-weight: 500
      &:first-child
        color: #696969
      &:last-child
        color: #000

.detail-size
  margin-top: 20px
  h3
    font-size: 14px
    color: #949494
    font-weight: 700
    margin-bottom: 5px

.detail-size-input
  width: 100%
  padding: 15px
  border: 1px solid #dbdbdb
  border-radius: 5px
  box-sizing: border-box
  font-size: 14px
  color: #000
  margin-bottom: 15px
  display: flex
  input
    flex: 1
    border: 0
    outline: 0
    font-size: 16px
    color: #000
    font-weight: 400
    &::placeholder
      color: #949494
      font-weight: 400
  span
    font-weight: 400
    color: #949494

.quantity-select
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px
  .quantity-title
    flex: 1
    font-weight: 700
  .quantity-input-area
    display: flex
    align-items: center
    button
      width: 24px
      height: 24px
      border-radius: 50%
      border: 0
      font-size: 24px
      color: #54585a
      line-height: 22px
      background-repeat: no-repeat
      background-position: center
      &:first-child
        margin-right: 5px
      &:last-child
        margin-left: 5px
    input
      height: 32px
      width: 124px
      padding-left: 10px
      box-sizing: border-box
      font-weight: 700
      font-size: 13px
      border: 1px solid #dbdbdb
      text-align: center
      border-radius: 5px

.total-price
  border-top: 2px solid #dbdbdb
  padding-top: 10px
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px
  span
    &:first-child
      font-size: 14px
      font-weight: 400
    &:last-child
      font-size: 20px
      font-weight: 700

.purchase-btn, .cart-btn
  font-family: 'Noto Sans KR', sans-serif
  width: 100%
  height: 50px
  background-color: #3d3d57
  color: #fff
  font-size: 16px
  font-weight: 700
  border: 0
  border-radius: 5px
  margin-top: 20px
  cursor: pointer
  &:disabled
    background-color: #dbdbdb
    color: #fff
    cursor: not-allowed