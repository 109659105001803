.member-address-view
  padding: 62px 20px 20px 20px
  min-height: calc(var(--vh, 1vh) * 100)
  box-sizing: border-box
  background-color: #ffffff

.address-edit-wrap
  padding: 62px 20px 20px 20px
  height: calc(var(--vh, 1vh) * 100)
  box-sizing: border-box
  background-color: #ffffff
  h1
    font-size: 24px
    font-weight: 700
    margin: 20px 0
  .address-edit-row
    position: relative
    margin-bottom: 20px
    p
      font-size: 14px
      font-weight: 700
      color: #949494
      margin-bottom: 5px
      &.err-msg
        font-size: 12px
        font-weight: 400
        position: absolute
        right: 0
        top: 0
        color: #e94439
    input
      font-family: "Noto Sans KR", sans-serif
      box-sizing: border-box
      padding: 0
      border-radius: 5px
      border: 1px solid #dbdbdb
      outline: none
      width: 100%
      height: 50px
      padding: 0 20px
      font-size: 16px
      &.error
        border-color: #E94439
    button
      font-family: "Noto Sans KR", sans-serif
      box-sizing: border-box
      padding: 0
      border-radius: 5px
      border: 0
      background-color: #F5F6F8
      color: #949494
      outline: none
      width: 100%
      height: 50px
      position: relative
      text-align: left
      padding-left: 20px
      font-size: 16px
      font-weight: 400
      &.error
        border: 1px solid #E94439
      img
        position: absolute
        width: 20px
        top: 15px
        right: 20px
  .submit-address, .cancel-address
    width: 100%
    height: 50px
    box-sizing: border-box
    padding: 0
    font-size: 16px
    font-weight: 700
    font-family: "Noto Sans KR", sans-serif
    color: #ffffff
    border: 0
    outline: none
    background-color: #3D3D57
    border-radius: 5px
    &.cancel-address
      margin-top: 10px
      background-color: #e0683a

.member-address-view
  h1
    font-size: 24px
    font-weight: 700
    margin: 20px 0
  .address-list
    max-height: calc(var(--vh, 1vh) * 70)
    overflow-y: auto
    .address-item
      position: relative
      padding: 20px
      border-radius: 5px
      background-color: #F5F6F8
      margin-bottom: 15px
      .address-title
        font-size: 16px
        font-weight: 700
        margin-bottom: 15px
      .address-content
        font-size: 14px
        font-weight: 400
        color: #54585A
        line-height: 1.5
      .address-btns
        position: absolute
        right: 20px
        top: 20px
        font-size: 14px
        font-weight: 700
        color: #3D3D57
        cursor: pointer
        button
          margin-left: 10px
          color: #949494
          font-size: 13px
          padding: 0
          border: 0
          background-color: transparent
          outline: none
          cursor: pointer

.add-address-btn
  margin-top: 20px
  width: 100%
  display: flex
  justify-content: left
  align-items: center
  height: 50px
  color: #54585a
  font-family: "Noto Sans KR", sans-serif
  font-weight: 700
  border: 0
  padding: 0 20px
  border-radius: 5px
  outline: none
  span
    margin-left: 10px