.email-find-view-wrap
  overflow-y: scroll
  background-color: #ffffff
  position: relative
.email-find-view, .password-find-view
  position: relative
  .terms-area
    bottom: 70px
  .next-btn-area
    @media screen and (max-height: 700px)
      background-color: #ffffff
      position: static
      width: calc(100% - 40px)
      margin: 10px auto
      padding-bottom: 20px
