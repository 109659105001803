.mypage-menu-wrap
  position: fixed
  top: 0
  width: 384px
  height: 100vh
  left: 100%
  background-color: rgba(0,0,0,0.3)
  z-index: 999999999999
  transition: left 0.3s
  &.show
    left: 50%
    @media (max-width: 768px)
      left: 0
  @media (max-width: 768px)
    width: 100%
  .mypage-menu
    height: 100vh
    position: absolute
    right: 0
    top: 0
    width: 90%
    box-sizing: border-box
    padding-top: 50px
    box-shadow: -2px 0 10px 0 rgba(0,0,0,0.5)
    background-color: #ffffff
.mypage-close-btn
  position: absolute
  right: 20px
  top: 20px
  border: 0
  background-color: transparent

.mypage-login-btn-area
  padding: 0 20px
  h1
    font-size: 20px
    margin-bottom: 20px
    color: #3d3d57
    font-weight: 700
  .mypage-login-btn
    display: block
    width: 100%
    height: 50px
    line-height: 50px
    text-decoration: none
    border: 1px solid #3d3d57
    border-radius: 5px
    text-align: center
    background-color: #3d3d57
    color: #ffffff
    font-weight: 700

.mypage-user-info
  padding: 0 20px
  h1
    font-size: 20px
    margin-bottom: 20px
    color: #3d3d57
    font-weight: 700
  .mypage-user-button-area
    display: flex
    .mypage-user-button
      display: block
      text-align: center
      height: 50px
      line-height: 50px
      text-decoration: none
      border-radius: 5px
      font-weight: 700
      &:nth-child(1)
        flex: 2
        background-color: #3d3d57
        color: #ffffff
        margin-right: 10px
      &:nth-child(2)
        flex: 1
        background-color: #F0F0F4
        color: #3D3D57
      margin-bottom: 20px

.mypage-menu-list
  border-top: 1px solid #dbdbdb
  .mypage-menu-item
    display: block
    padding: 15px 20px
    border-bottom: 1px solid #dbdbdb
    text-decoration: none
    color: #3d3d57

.mypage-sign-out-btn-area
  padding: 20px
  position: absolute
  bottom: 0
  a, button
    color: #3D3D57
    text-decoration: none
    font-weight: 400
    border: 0
    background-color: transparent
    font-size: 16px
    font-family: 'Noto Sans KR', sans-serif
    padding: 0
  span
    color: #3d3d57
    font-size: 14px
    margin: 0 10px
    position: relative
    top: -1px