.order-history-view-wrap
  overflow: hidden 
  .order-history-item-info
    box-sizing: border-box
    padding: 20px
    padding-top: 40px
    min-height: calc(var(--vh, 1vh) * 10)
    background-color: #ffffff
    overflow-y: auto
    
    h1
      font-size: 24px
      font-weight: 700
      line-height: 1.5
      margin-top: 20px
      margin-bottom: 20px

    .order-summary
      display: flex
      .order-summary-item
        flex: 1
        flex-direction: column
        text-align: center
        padding: 10px
        background-color: #f8f8f8
        margin-right: 10px
        white-space: nowrap
        cursor: default 
        &:hover
          cursor: default 

        &.active
          background-color: #3D3D57
          color: white
        .order-summary-item-count
          margin-top: 5px
          font-size: 18px
          font-weight: bold
  .order-history-item-list
  
    min-height: calc(100vh - 180px)
    background-color: #fff

    .order-history-item
      width: 100% 
      border-bottom: 1px solid #dbdbdb
      padding: 10px 0
      display: flex
      flex-direction: column

      .order-history-item-top
        display: flex
        align-items: center 
        padding:10px
        .order-history-item-image
          flex-shrink: 0
          width: 80px
          height: 80px
          margin-right: 10px
          img, .order-history-3d-area
            width: 100%
            height: 100%
            object-fit: cover

        .order-details
          flex: 1
          margin-left : 5px
          .order-date
            color: #999
            margin-bottom: 5px
          .order-number
            color: #999
          .order-name
            font-size: 16px
            font-weight: 700
            padding-top : 5px
          .order-price
            color: #3d3d57
            padding-top : 5px
          .order-status
            padding-top : 7px
            font-weight: 500
            &.결제완료
              color: #FF6F61
            &.배송중
              color: #FF9500
            &.환불
              color: #007AFF
            &.배송완료
              color: #3D3D57

      .order-actions
        width: 100% 
        display: flex
        justify-content: center 
        box-sizing: border-box 
        padding: 10px
        button
          background: #F5F6F8
          padding: 10px
          cursor: pointer
          border: none 
          font-size: 14px
          font-weight: 700
          text-align: center
          width: 100% 
          box-sizing: border-box
          &:not(:last-child)
            margin-right: 0 
