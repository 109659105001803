.module-guide-comp-wrap
  padding: 82px 20px 20px 20px
  box-sizing: border-box
  min-height: calc(var(--vh, 1vh) * 100)
  position: relative
  background-color: #ffffff
  .module-guide
    h1
      font-size: 24px
      font-weight: 700
      margin-bottom: 10px
      line-height: 1.5
    p
      font-size: 14px
      line-height: 1.5
      margin-bottom: 20px
    .guide-area
      text-align: center
      display: flex
      &.step-2
        flex-direction: column
      .guide
        background-color: #F5F6F8
        box-sizing: border-box
        padding: 20px
        flex: 1


.guide-area.step-1
  .guide
    &:first-child
      margin-right: 10px
    &:last-child
      margin-left: 10px
  img:first-child
    width: 32px
    margin-bottom: 10px
  img:last-child
    height: 154px
    margin: 50px 0

.guide-area.step-2
  .guide
    &:first-child
      margin-bottom: 20px
  img:first-child
    width: 32px
    margin-bottom: 10px
  img:last-child
    height: 107px
    margin: 20px 0

.next-btn
  margin-top: 20px
  box-sizing: border-box
  border: 0
  font-family: 'Noto Sans KR', sans-serif
  font-size: 16px
  font-weight: 700
  width: 100%
  height: 50px
  border-radius: 5px
  color: #ffffff
  background-color: #3D3D57
