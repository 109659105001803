.email-find-component-wrap
  height: calc(var(--vh, 1vh) * 100)
  position: absolute
  left: 0
  top: 0
  background-color: #ffffff
  width: 100%
  box-sizing: border-box
  padding-top: 62px
  text-align: center
  .mail-icon
    width: 86px
    margin-top: 50px
  h1
    font-size: 24px
    line-height: 1.5
    font-weight: 700
    letter-spacing: -0.5px
    margin-bottom: 20px
  .finded-email
    width: calc(100% - 40px)
    margin: 0 auto
    background-color: #F5F6F8
    display: flex
    box-sizing: border-box
    padding: 10px 20px
    justify-content: space-between
    align-items: center
    border-radius: 5px
    margin-bottom: 40px
    button
      padding: 0
      margin: 0
      border: 0
      background-color: transparent
      outline: none
      img
        width: 28px

.email-find-comp-link
  display: block
  width: calc(100% - 40px)
  margin: 0 auto
  text-align: center
  font-size: 16px
  font-weight: 700
  line-height: 50px
  height: 50px
  text-decoration: none
  border-radius: 5px
  margin-bottom: 10px
  &:first-child
    background-color: #3D3D57
    color: #ffffff
  &:last-child
    background-color: #F5F6F8
    color: #54585A
