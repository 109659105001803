.product-view-header
  position: fixed
  width: 384px
  background-color: #fff
  z-index: 9
  &.floating
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5)
  @media screen and (max-width: 768px)
    width: 100%
  h1
    font-size: 22px
    padding: 10px 20px
    padding-top: 72px
    width: 100%
    box-sizing: border-box
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    span
      font-weight: 400
      &:first-child
        font-weight: 800
      .page-title-sep
        margin: 0 10px
        font-weight: 400
        font-size: 20px
        position: relative
        top: -2px
.product-view-content
  padding-top: 162px
  width: 384px
  background-color: #fff
  @media screen and (max-width: 768px)
    width: 100%
.product-view-wrap
  position: relative
  overflow: hidden