.module-list-comp-wrap
  border-radius: 15px 15px 0 0
  position: fixed
  top: 100%
  height: 100%
  width: 384px
  left: 50%
  background-color: #fff
  z-index: 999
  transition: top 0.3s
  &.open
    top: 62px
  > h1
    font-size: 24px
    font-weight: 700
    padding: 20px
    span:last-child
      font-size: 12px
      margin-left: 10px
  @media screen and (max-width: 768px)
    width: 100%
    left: 0
.module-list
  height: calc(var(--vh, 1vh) * 70.6)
  overflow-y: scroll
  box-sizing: border-box
  padding: 20px
  padding-bottom: 80px
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 20px
  .module-item
    position: relative
    border-radius: 5px
    background-color: #ffffff
    padding: 10px
    border: 1px solid #dbdbdb
    &.selected
      border: 1px solid #E0683A
    .module-name
      display: flex
      align-items: center
      justify-content: space-between
      h2
        font-size: 13px
      p
        font-size: 10px
        font-weight: 700
        color: #e0683a
    .module-image
      text-align: center
      img
        height: 113px
    .module-price
      font-size: 14px
      font-weight: 700
      color: #3d3d57
    .wishlist-btn
      position: absolute
      right: 1px
      bottom: 3px
      img
        width: 24px
.module-list-comp-header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 20px
  border-bottom: 1px solid #dbdbdb
  h2
    font-size: 16px
    font-weight: 700
    color: #3D3D57
  button
    background-color: transparent
    border: 0

.module-actions
  display: flex
  position: fixed
  left: 50%
  width: 384px !important
  bottom: 0
  background-color: #fff
  box-shadow: 0 -3px 5px rgba(0,0,0,0.3)
  justify-content: center
  align-items: center
  gap: 20px
  width: 100%
  padding: 20px 0
  @media screen and (max-width: 768px)
    width: 100% !important
    left: 0px


.module_add, .module_details
  flex: 1
  max-width: 150px
  padding: 0
  height: 50px
  font-family: "Noto Sans KR", sans-serif
  font-weight: 700
  color: #ffffff
  border-radius: 5px
  border: 0
  font-size: 16px
  outline: none
  display: flex
  justify-content: center
  align-items: center

.module_add
  background-color: #E0683A

.module_details
  background-color: #4A90E2