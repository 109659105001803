.wishlist-view-wrap
  background-color: #ffffff
  min-height: calc(var(--vh, 1vh) * 100)
.wishlist-title
  padding: 82px 20px 20px 20px
  font-size: 24px
  font-weight: 700

.wishlist-tab
  margin-top: 20px
  display: flex
  justify-content: space-around
  .wishlist-tab-item
    flex: 1
    text-align: center
    padding-bottom: 10px
    font-size: 14px
    font-weight: 700
    color: #949494
    border-bottom: 2px solid #dbdbdb
    cursor: pointer
    user-select: none
    &.active
      color: #E0683A
      border-bottom: 2px solid #E0683A

.product-wishlist-item
  position: relative
  padding: 20px
  box-sizing: border-box
  border-bottom: 1px solid #dbdbdb
  display: flex
  .product-wishlist-item-img
    width: 140px
    height: 140px
    background-size: 100%
    background-position: center
    border-radius: 5px
  .product-wishlist-item-info
    margin-left: 12px
    .product-furnitrue-type, .product-size
      font-size: 10px
      font-weight: 400
      margin-bottom: 5px
      &.product-size
        margin-bottom: 10px
    .product-name
      font-size: 16px
      font-weight: 700
      margin-bottom: 5px
    .product-changeable-list
      display: flex
      align-items: center
      margin-bottom: 20px
      .product-changeable-item
        color: #54585A
        font-size: 10px
        font-weight: 700
        margin-right: 2px
        padding: 5px
        border-radius: 20px
        &.size-changeable
          color: #3D3D57
          background-color: #F0F0F4
        &.color-changeable
          color: #E0683A
          background-color: #FCF0EB
        &.texture-changeable
          color: #3D3D57
          background-color: #D7F1FF
    .product-price
      font-size: 16px
      font-weight: 700
      margin-left: auto
      margin-bottom: 10px
      span:last-child
        font-size: 12px
        margin-left: 3px
    .product-review-cnt
      font-size: 10px
      span:first-child
        font-weight: 700
      span:last-child
        margin-left: 5px
        color: #646479
    .wishlist-btn
      position: absolute
      right: 20px
      bottom: 23px


.module-wishlist-item
  position: relative
  border-radius: 5px
  background-color: #ffffff
  padding: 10px
  border: 1px solid #dbdbdb
  &.selected
    border: 1px solid #E0683A
  .module-wishlist-name
    display: flex
    align-items: center
    justify-content: space-between
    h2
      font-size: 13px
    p
      font-size: 10px
      font-weight: 700
      color: #e0683a
  .module-wishlist-image
    text-align: center
    img
      height: 113px
  .module-wishlist-price
    font-size: 14px
    font-weight: 700
    color: #3d3d57
  .wishlist-btn
    position: absolute
    right: 10px
    bottom: 23px