.guide-finish-area-wrap
  background-color: #3D3D57
  min-height: calc(var(--vh, 1vh) * 100)
  box-sizing: border-box
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative
  .guide-finish
    text-align: center
    color: #ffffff
    img
      width: 54px
      filter: invert(1)
      animation: 2s loading infinite linear
    >*
      margin-bottom: 20px
      line-height: 1.5
    h1
      font-size: 24px
    p
      font-size: 14px
      font-weight: 400
.finish-guide
  width: calc(100% - 40px)
  height: 50px
  box-sizing: border-box
  font-family: "Noto Sans KR", sans-serif
  font-weight: 700
  background-color: #ffffff
  border: 0
  border-radius: 5px
  font-size: 16px
  color: #3D3D57
  position: absolute
  bottom: 20px
  left: 20px

@keyframes loading
  0%
    transform: rotate(0deg)
  12.5%
    transform: rotate(-90deg)
  25%
    transform: rotate(-90deg)
  37.5%
    transform: rotate(-180deg)
  50%
    transform: rotate(-180deg)
  62.5%
    transform: rotate(-270deg)
  75%
    transform: rotate(-270deg)
  87.5%
    transform: rotate(-360deg)
  100%
    transform: rotate(-360deg)