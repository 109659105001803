.review-component
  max-width: 600px
  margin: 20px auto
  padding: 20px
  border: 1px solid #ddd
  border-radius: 10px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
  background-color: #fff

  h2
    margin-bottom: 20px
    text-align: center
    color: #333

  form
    display: flex
    flex-direction: column

    input[type="text"]
      width: 95%
      height: 40px
      margin-bottom: 10px
      padding: 10px
      border: 1px solid #ccc
      border-radius: 5px
      font-size: 16px
      &:focus
        outline: none
        border-color: #666

    textarea
      width: 95%
      height: 100px
      margin-bottom: 10px
      padding: 10px
      border: 1px solid #ccc
      border-radius: 5px
      font-size: 16px
      resize: vertical
      &:focus
        outline: none
        border-color: #666

    .rating
      display: flex
      align-items: center
      margin-bottom: 10px

      label
        margin-right: 10px

    .image-upload
      display: flex
      flex-direction: column
      margin-bottom: 10px

      label
        margin-bottom: 5px
        font-weight: bold
        color: #333

      input[type="file"]
        margin-bottom: 10px

      img
        width: 100px
        height: 100px
        object-fit: cover
        margin-top: 10px
        border: 1px solid #ddd
        border-radius: 5px

    button
      padding: 10px
      background-color: #333
      color: #fff
      border: none
      border-radius: 5px
      cursor: pointer
      font-size: 16px
      transition: background-color 0.3s
      &:hover
        background-color: #555
      &:focus
        outline: none

@media (max-width: 600px)
  .review-component
    padding: 10px
    form
      input[type="text"],
      textarea
        font-size: 14px
      button
        font-size: 14px
