.product-filter-area
  padding: 20px 15px 10px 15px
  display: flex
  width: 384px
  box-sizing: border-box
  @media screen and (max-width: 768px)
    width: 100%
  select
    background-color: #ffffff
    font-family: 'Noto Sans KR', sans-serif
    flex: 1
    margin: 0 5px
    padding: 5px 10px
    border-radius: 5px
    color: #646479
    border-color: #dbdbdb
    outline: none
    &:first-child
      width: 80px