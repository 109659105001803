.category-menu-wrap
  position: fixed
  top: 0
  left: 50%
  width: 384px
  z-index: 9999999
  background-color: #ffffff
  height: 100vh
  box-sizing: border-box
  overflow: hidden
  @media (max-width: 768px)
    width: 100%
    left: 0
  .rootCategory-list
    padding-top: 62px
    top: 0
    width: 100%
    background-color: #fff
    box-sizing: border-box
    //height: 100%
  .subCategories
    top: 0
    position: absolute
    left: 100%
    z-index: 9
    width: 384px
    padding-top: 62px
    transition: left 0.1s
    background-color: #fff
    box-sizing: border-box
    height: 100%
    @media (max-width: 768px)
      width: 100%
    &.show
      left: 0
    .subCategory-list
      display: none
      &.show
        display: block
  .subSubCategories
    top: 0
    position: absolute
    left: 100%
    z-index: 10
    width: 384px
    padding-top: 62px
    transition: left 0.1s
    background-color: #fff
    box-sizing: border-box
    height: 100%
    @media (max-width: 768px)
      width: 100%
    &.show
      left: 0
    .sub-subCategory-list
      display: none
      &.show
        display: block
.category-menu-title, .my-page-btn
  font-size: 24px
  font-weight: 700
  padding: 20px
  user-select: none
  cursor: pointer
  display: flex
  align-items: center
  span
    font-weight: 300
    &:first-of-type
      font-weight: 800
    &:nth-of-type(2)
      font-size: 20px
      margin: 0 10px
      font-weight: 300
  &.my-page-btn
    display: flex
    justify-content: space-between

.category-menu-btn
  display: flex
  justify-content: space-between
  padding: 20px
  font-weight: 400
  border-bottom: 1px solid #dbdbdb
  user-select: none
  cursor: pointer
  &:hover
      background-color: #f0f0f4
.sub-category-chevron
  color: #949494

.chevron-reverse
  transform: rotate(180deg)
  margin-right: 10px
  filter: brightness(0)
  position: relative
  top: 1px
