.furniture-form-select-comp-wrap
  padding: 82px 20px 20px 20px
  box-sizing: border-box
  min-height: calc(var(--vh, 1vh) * 100)
  position: relative
  background-color: #ffffff
  h1
    font-weight: 400
    font-size: 24px
    line-height: 1.5
    margin-bottom: 20px
  p
    font-size: 14px
    line-height: 1.5

.furniture-form-select-list
  display: flex
  flex-direction: column
  height: calc(var(--vh, 1vh) * 70)
  .furniture-form
    cursor: pointer
    margin-top: 20px
    flex: 1
    display: flex
    flex-direction: column
    .form-thumbnail
      border-radius: 5px 5px 0 0
      flex: 1
      background-size: 100%
      background-position: center
      background-repeat: no-repeat
    .text
      border-radius: 0 0 5px 5px
      padding: 20px
      background-color: #3D3D57
      color: #ffffff
      h2
        font-size: 20px
        font-weight: 700
        margin-bottom: 10px
      p
        font-size: 13px
