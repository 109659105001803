.not-found-page-wrap
  height: calc(var(--vh, 1vh) * 100)
  box-sizing: border-box
  background-color: #ffffff
  .not-found-page
    display: flex
    height: 100%
    flex-direction: column
    justify-content: center
    align-items: center
    img
      width: 130px
    h1
      font-size: 24px
      font-weight: 700
      margin: 10px 0
      color: #3d3d57
    .go-to-main
      margin-top: 20px
      font-size: 16px
      font-weight: 700
      background-color: #3d3d57
      text-decoration: none
      height: 50px
      line-height: 50px
      text-align: center
      width: calc(100% - 40px)
      border-radius: 5px
      color: #ffffff
