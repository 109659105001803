.add-to-cart-popup-wrap
  position: fixed
  background-color: #fff
  top: 0
  z-index: 99999999999
  background-color: rgba(0, 0, 0, 0.5)
  width: 384px
  @media screen and (max-width: 768px)
    width: 100%
  height: calc(var(--vh, 1vh) * 100)
  .add-to-cart-popup
    border-radius: 10px 10px 0 0
    background-color: #ffffff
    width: 100%
    box-sizing: border-box
    position: absolute
    left: 0
    bottom: 0
    box-sizing: border-box
    padding: 20px
    text-align: center
    img
      width: 44px
      margin-bottom: 10px
    p
      font-size: 18px
      font-weight: 700
      margin-bottom: 20px
      letter-spacing: -0.5px
    .btn-area
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      > *
        width: 100%
        line-height: 50px
        height: 50px
        border-radius: 5px
        border: 0
        font-weight: 700
        outline: none
        cursor: pointer
        font-family: "Noto Sans KR", sans-serif
      a
        margin-bottom: 10px
        background-color: #F5F6F8
        color: #54585a
        text-decoration: none
      button
        background-color: #3D3D57
        color: #ffffff
        cursor: pointer
        font-family: "Noto Sans KR", sans-serif
        font-size: 16px

