

.p
  padding: 0
  margin: 0
  font-family: Toss Product Sans, -apple-system, BlinkMacSystemFont, Bazier Square, Noto Sans KR, Segoe UI, Apple SD Gothic Neo, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
  color: #4e5968
  word-break: keep-all
  word-wrap: break-word

.wrapper
  max-width: 800px
  margin: 0 auto

.box_section
  background-color: white
  border-radius: 10px
  box-shadow: 0 10px 20px rgb(0 0 0 / 1%), 0 6px 6px rgb(0 0 0 / 6%)
  padding: 50px
  margin-top: 30px
  margin-right: auto
  margin-left: auto
  color: #333d4b
  align-items: center
  text-align: center
  overflow-x: auto // Add this property for horizontal scrolling
  white-space: nowrap // Prevent text wrapping

.btn-payment
  color: #f9fafb
  background-color: #3182f6
  margin: 30px 15px 0px 15px
  font-size: 15px
  font-weight: 600
  line-height: 18px
  white-space: nowrap
  text-align: center
  cursor: pointer
  border: 0 solid transparent
  user-select: none
  transition: background 0.2s ease, color 0.1s ease
  text-decoration: none
  border-radius: 7px
  padding: 11px 16px
  width: 250px

.button:hover
  color: #fff
  background-color: #1b64da

.btn-wrapper
  width: 100%
  text-align : center

.typography
  margin: 0
  padding: 0

.typography--p
  line-height: 1.6
  font-size: 15px

.text--center
  text-align: center

.text--justify
  text-align: justify
