.payment-item-info
  box-sizing: border-box
  padding: 20px
  padding-top: 62px
  min-height: calc(var(--vh, 1vh) * 100)
  background-color: #ffffff
  overflow-y: auto
  h1
    font-size: 24px
    font-weight: 700
    line-height: 1.5
    margin-top: 20px
    margin-bottom: 20px
  input
    width: calc(100% - 30px)

.payment-item-list
  max-height: calc(var(--vh, 1vh) * 30)
  overflow-y: auto
  height: auto

.payment-item
  border: 1px solid #dbdbdb
  border-radius: 5px
  margin-bottom: 10px
  &.selected
    border-color: #3d3d57

.payment-item-header
  padding: 15px
  position: relative
  input
    display: none
  label
    background-color: #fff
    cursor: pointer
    border: 1px solid #dbdbdb
    display: inline-block
    width: 20px
    height: 20px
    border-radius: 50%
    background-size: 12px auto
    background-position: center
    background-repeat: no-repeat
  input:checked+label
    background-color: #3D3D57
    border-color: #3D3D57
  button
    border: 0
    background-color: transparent
    position: absolute
    right: 15px
    top: 15px

.payment-item-body
  display: flex
  width: calc(100% - 30px)
  margin: 0 auto
  padding-bottom: 15px
  border-bottom: 1px solid #dbdbdb
  box-sizing: border-box
  .payment-item-img
    border-radius: 5px
    box-sizing: border-box
    flex-shrink: 0
    width: 105px
    height: 105px
    background-repeat: no-repeat
    background-size: cover
    background-position: center
  .payment-item-detail
    box-sizing: border-box
    flex: 1
    margin-left: 15px
    .payment-item-title
      font-size: 16px
      font-weight: 700
      margin-bottom: 10px
    .payment-item-term
      font-size: 11px
      font-weight: 700
      margin-bottom: 10px
    .payment-item-price
      font-size: 15px
      font-weight: 700
      margin-bottom: 10px
    .payment-item-count
      font-size: 13px
      font-weight: 700
      margin-bottom: 10px
    .payment-item-price-sum
      font-size: 14px
      font-weight: 600
      color: #3D3D57
      margin-top: 5px

.shipping-payment-info
  h1
    font-size: 24px
    font-weight: 700
    margin: 20px 0
  .address-list
    max-height: calc(var(--vh, 1vh) * 30)
    overflow-y: auto
    .address-item
      position: relative
      padding: 20px
      border-radius: 5px
      background-color: #F5F6F8
      margin-bottom: 15px
      cursor: pointer
      &.selected
        border: 2px solid #3D3D57
      .address-title
        font-size: 16px
        font-weight: 700
        margin-bottom: 15px
      .address-content
        font-size: 14px
        font-weight: 400
        color: #54585A
        line-height: 1.5
      .address-btns
        position: absolute
        right: 20px
        top: 20px
        font-size: 14px
        font-weight: 700
        color: #3D3D57
        cursor: pointer
        button
          margin-left: 10px
          color: #949494
          font-size: 13px
          padding: 0
          border: 0
          background-color: transparent
          outline: none
          cursor: pointer

.payment-view-btn-area
  margin-top: 20px
  display: flex
  padding: 20px
  box-sizing: border-box
  button
    border-radius: 5px
    font-family: "Noto Sans KR", sans-serif
    font-size: 16px
    font-weight: 700
    height: 50px
    border: 0
    outline: none
    cursor: pointer
    &:first-child
      flex: 2
      background-color: #3d3d57
      color: #ffffff
      font-weight: 700
      margin-right: 10px
    &:last-child
      background-color: #f5f6f8
      color: #54585a
      flex: 1
    &:disabled
      background-color: #dbdbdb
.payment-total-price
  font-size: 20px
  font-weight: 700
  color: #3D3D57
  text-align: right
  margin-top: 20px
  border-top: 1px solid #dbdbdb
  padding-top: 20px