.password-change-view-wrap
  position: absolute
  z-index: 999999999999
  left: 100%
  top: 0
  height: calc(var(--vh, 1vh) * 100)
  background-color: #ffffff
  width: 100%
  box-sizing: border-box
  transition: left 0.3s
  &.open
    left: 0
  .password-change-view-header
    padding: 20px
    position: relative
    text-align: center
    img
      position: absolute
      transform: rotate(180deg)
      left: 20px
      top: 20px
    span
      font-weight: 700
      font-size: 14px
  .password-change-view
    padding: 20px
    h1
      font-size: 24px
      line-height: 1.5
      margin-bottom: 30px

.password-change-view-row
  position: relative
  margin-bottom: 20px
  .pwd-change-view-row-title
    font-size: 14px
    color: #949494
    margin-bottom: 5px
    font-weight: 700
  input
    width: 100%
    outline: none
    height: 50px
    border: 1px solid #dbdbdb
    font-family: 'Noto Sans KR', sans-serif
    border-radius: 5px
    padding: 0 20px
    box-sizing: border-box
    font-size: 16px
    color: #000000
    &.error
      border-color: #e94439
  .err-msg
    font-size: 12px
    font-weight: 400
    color: #e94439
    margin-top: 5px
    margin-bottom: 20px
    position: absolute
    right: 0
    top: -5px
.password-change-btn
  border: 0
  font-family: "Noto Sans KR", sans-serif
  font-size: 16px
  padding: 0
  margin-top: 20px
  display: block
  width: 100%
  height: 50px
  border-radius: 5px
  line-height: 50px
  text-align: center
  background-color: #3D3D57
  color: #ffffff
  font-weight: 700
  cursor: pointer

.password-change-finished-wrap
  position: absolute
  z-index: 999999999999
  left: 0
  top: 0
  height: calc(var(--vh, 1vh) * 100)
  background-color: #ffffff
  width: 100%
  box-sizing: border-box
  display: none
  &.show
      display: block
  .password-change-finished
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%
    text-align: center
    img
      width: 86px
    h1
      font-size: 24px
      line-height: 1.5
      font-weight: 700
      margin-bottom: 20px
    p
      font-size: 14px
      font-weight: 400
      margin-bottom: 100px
      color: #5f5f5f
      line-height: 1.5
    .password-change-finished-login, .password-change-finished-home
      display: block
      width: calc(100% - 40px)
      margin: 0 auto
      text-align: center
      font-size: 16px
      font-weight: 700
      line-height: 50px
      height: 50px
      text-decoration: none
      border-radius: 5px
      margin-bottom: 10px
      &.password-change-finished-login
        background-color: #3D3D57
        color: #ffffff
      &.password-change-finished-home
        background-color: #F5F6F8
        color: #54585A