.review-detail-wrap
  width: 384px
  position: relative
  @media screen and (max-width: 768px)
    width: 100%

.review-detail-content
  padding-top: 62px
  background-color: #ffffff
  .review-image
    position: relative
    width: 100%
    padding-bottom: 100%
    background-position: center
    background-repeat: no-repeat
    background-size: cover

.review-basic-info
  padding: 20px
  .review-title
    font-size: 20px
    font-weight: 700
    margin-bottom: 5px

.review-detail
  padding: 20px
  img
    width: 100%
    margin-bottom: 20px
  b, h1
    display: block
    margin-bottom: 10px
  p
    font-size: 14px
    line-height: 1.5

