.furniture-type-select-comp-wrap
  padding: 82px 20px 20px 20px
  box-sizing: border-box
  min-height: calc(var(--vh, 1vh) * 100)
  background-color: #ffffff
  > h2
    font-size: 24px
    line-height: 1.5
    font-weight: 400
    margin-bottom: 20px
    b
      font-weight: 700

.furniture-type-select-list
  .furniture-type
    user-select: none
    cursor: pointer
    position: relative
    box-sizing: border-box
    padding: 20px
    background-size: auto 120%
    background-repeat: no-repeat
    background-position: center right
    background-color: #F5F6F8
    margin-bottom: 20px
    height: calc(var(--vh, 1vh) * 16.8)
    .cover
      width: 100%
      height: 100%
      position: absolute
      left: 0
      top: 0
      background-color: rgba(255, 255, 255, 0.6)
      z-index: 9
      display: none
    &.disabled
      filter: grayscale(100%)
      .cover
        display: block
    &:last-child
      margin-bottom: 0
    h2
      font-size: 20px
      font-weight: 700
      margin-bottom: 10px
    p
      font-size: 13px
.create-furniture
  position: absolute
  left: 20px
  bottom: 20px
  font-size: 12px
  font-weight: 700
  > *
    display: inline-block
    vertical-align: middle
  img
    margin-left: 5px