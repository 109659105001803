.find-password-view-wrap
  position: relative

.find-password-finished-wrap
  position: absolute
  left: 0
  top: 0
  height: calc(var(--vh, 1vh) * 100)
  background-color: #ffffff
  width: 100%
  box-sizing: border-box
  padding-top: 62px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  img
    width: 86px
  h1
    font-size: 20px
    line-height: 1.5
    font-weight: 700
    margin-bottom: 20px
    padding: 0 20px
  p
    font-size: 14px
    font-weight: 400
    margin-bottom: 100px
    color: #5f5f5f
    line-height: 1.5
  .find-password-finished-login, .find-password-finished-home
    display: block
    width: calc(100% - 40px)
    margin: 0 auto
    text-align: center
    font-size: 16px
    font-weight: 700
    line-height: 50px
    height: 50px
    text-decoration: none
    border-radius: 5px
    margin-bottom: 10px
    &.find-password-finished-login
      background-color: #3D3D57
      color: #ffffff
    &.find-password-finished-home
      background-color: #F5F6F8
      color: #54585A


