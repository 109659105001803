.product-list-wrap
  width: 100%
  padding-top : 60px
.product-list
  width: 100%
  min-height: calc(100vh - 162px)
  background-color: #fff
  
.product-item
  width: 100%
  box-sizing: border-box
  display: flex
  position: relative
  flex-direction: row
  align-items: center
  padding: 20px
  border-bottom: 1px solid #dbdbdb
  &:last-child
    border-bottom: none
  .product-image
    width: 140px
    height: 140px
    border-radius: 5px
    flex-shrink: 0
    background-size: 100%
    background-position: center
  .product-info
    position: relative
    width: calc(100% - 150px)
    height: 140px
    margin-left: 10px
    .product-type
      font-size: 10px
      margin-bottom: 10px
    .product-name
      font-size: 16px
      font-weight: 800
      margin-bottom: 10px
      width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .product-size
      font-size: 10px
      color: #646479
      margin-bottom: 10px
    .product-changeable-list
      margin-bottom: 10px
      div
        display: inline-block
        font-size: 10px
        font-weight: 800
        padding: 5px 7px
        margin-right: 3px
        border-radius: 20px
        &.size-changeable
          background-color: #f0f0f4
          color: #3d3d57
        &.color-changeable
          background-color: #fcf0eb
          color: #e0683a
        &.texture-changeable
          background-color: #d7f0ff
          color: #3d3d57
    .product-price
      margin-bottom: 5px
      span
        display: inline-block
        vertical-align: bottom
        &:first-child
          font-weight: 800
        &:last-child
          font-size: 12px
    .product-review-and-like-count
      display: flex
      justify-content: space-between
      .product-review-count
        span
          font-size: 10px
          &:first-child
            font-weight: 800
          &:last-child
            color: #646479
            margin-left: 3px
      .product-like-count
        img
          width: 15px
          height: 15px
          vertical-align: middle
          position: relative
          top: -1px
        span
          font-size: 10px
          display: inline-block
          vertical-align: middle
          &:first-child
            color: #646479
          &:last-child
            color: #dbdbdb
            font-size: 15px
            margin-left: 3px

.product-bottom-area
  position: absolute
  bottom: 0px
  width: 100%

.no-product
  color: #b1b1bc
  position: absolute
  width: 100%
  text-align: center
  top: 50%
  transform: translateY(-50%)
  img
    margin-bottom: 20px