.member-cart-view
  box-sizing: border-box
  padding: 20px
  padding-top: 62px
  min-height: calc(var(--vh, 1vh) * 100)
  background-color: #ffffff
  h1
    font-size: 24px
    font-weight: 700
    line-height: 1.5
    margin-top: 20px
    margin-bottom: 20px
.member-cart-item
  border: 1px solid #dbdbdb
  border-radius: 5px
  margin-bottom: 20px
  &.selected
    border-color: #3d3d57
.member-cart-item-header
  padding: 15px
  position: relative
  input
    display: none
  label
    background-color: #fff
    cursor: pointer
    border: 1px solid #dbdbdb
    display: inline-block
    width: 20px
    height: 20px
    border-radius: 50%
    background-size: 12px auto
    background-position: center
    background-repeat: no-repeat
  input:checked+label
    background-color: #3D3D57
    border-color: #3D3D57
  button
    border: 0
    background-color: transparent
    position: absolute
    right: 15px
    top: 15px

.member-cart-item-body
  display: flex
  width: calc(100% - 30px)
  margin: 0 auto
  padding-bottom: 15px
  border-bottom: 1px solid #dbdbdb
  box-sizing: border-box
  .member-cart-item-img
    border-radius: 5px
    box-sizing: border-box
    flex-shrink: 0
    width: 105px
    height: 105px
    background-repeat: no-repeat
    background-size: cover
    background-position: center
  .member-cart-item-detail
    box-sizing: border-box
    flex: 1
    margin-left: 15px
    .cart-item-title
      font-size: 16px
      font-weight: 700
      margin-bottom: 10px
    .cart-item-term
      font-size: 11px
      color: #e0683a
      font-weight: 700
      margin-bottom: 10px
    .cart-item-price
      font-size: 15px
      font-weight: 700
      margin-bottom: 10px
    .cart-item-count
      display: flex
      align-items: center
      button
        width: 22px
        height: 22px
        border: 0
        padding: 0
        background-position: center
        background-repeat: no-repeat
        border-radius: 50%
        background-color: #f5f6f8
      input
        padding: 0
        margin: 0 10px
        width: 80%
        max-width: 110px
        height: 30px
        outline: none
        flex-basis: 110px
        border-radius: 5px
        border: 1px solid #dbdbdb
        font-family: "Noto Sans KR", sans-serif
        font-size: 12px
        text-align: center
        font-weight: 700
.cart-product-size
  padding: 15px
  font-size: 12px
  display: flex
  span
    &:first-child
      flex: 1
      font-weight: 700
    &:last-child
      flex: 4

.modify-design
  width: calc(100% - 30px)
  display: flex
  align-items: center
  justify-content: space-between
  margin: 0 auto
  margin-bottom: 15px
  height: 50px
  box-sizing: border-box
  border: 0
  font-family: "Noto Sans KR", sans-serif
  background-color: #f0f0f4
  color: #242627
  font-weight: 700
  text-align: left
  padding: 0 15px

.member-cart-view-btn-area
  position: fixed
  bottom: 0
  width: 384px
  left: 50%
  padding: 20px
  box-sizing: border-box
  display: flex
  @media screen and (max-width: 768px)
    width: 100%
    left: 0
    padding: 10px
  button
    border-radius: 5px
    font-family: "Noto Sans KR", sans-serif
    font-size: 16px
    font-weight: 700
    height: 50px
    border: 0
    outline: none
    cursor: pointer
    &:first-child
      flex: 2
      background-color: #3d3d57
      color: #ffffff
      font-weight: 700
      margin-right: 10px
    &:last-child
      background-color: #f5f6f8
      color: #54585a
      flex: 1
    &:disabled
      background-color: #dbdbdb

.member-cart-list
  height: calc(var(--vh, 1vh) * 74)
  overflow-y: scroll