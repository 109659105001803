.furniture-keyword-select-comp-wrap
  padding: 82px 20px 20px 20px
  box-sizing: border-box
  min-height: calc(var(--vh, 1vh) * 100)
  position: relative
  background-color: #ffffff
  > h2
    font-size: 24px
    line-height: 1.5
    margin-bottom: 10px
  > p
    line-height: 1.5
    font-size: 14px
    margin-bottom: 30px

.furniture-keyword-select-list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 10px
  .furniture-keyword
    background-color: #f0f0f4
    padding: 15px 20px
    color: #3d3d57
    text-align: center
    border-radius: 50px
    cursor: pointer
    &.selected
      background-color: #3d3d57
      color: #ffffff

.select-complete-btn
  width: calc(100% - 40px)
  box-sizing: border-box
  border: 0
  height: 50px
  position: absolute
  font-family: 'Noto Sans KR', sans-serif
  font-size: 16px
  font-weight: 700
  color: #ffffff
  background-color: #3D3D57
  border-radius: 5px
  bottom: 20px
  &:disabled
    background-color: #dbdbdb
    cursor: not-allowed