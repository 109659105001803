.module-detail-wrap
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  align-items: center
  justify-content: center
  z-index: 1000

.module-detail-content
  background-color: #fff
  padding: 20px
  border-radius: 10px
  max-width: 600px
  width: 80%
  .module-image
    width: 90%
    height: 300px
    background-size: cover
    background-position: center
    margin-bottom: 20px
  .second-image
    width: 100%
    img
      width: 90%
      height: 300px
      object-fit: cover
      margin-top: 10px
  .module-basic-info
    .module-title
      font-size: 24px
      margin-bottom: 10px
    .module-specs
      margin-bottom: 10px
    .module-price
      font-size: 20px
      margin-bottom: 10px

  button
    margin-top: 20px
