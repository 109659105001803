.modal-popup-wrap
  position: fixed
  top: 0
  height: 100vh
  background-color: rgba(0,0,0,0.5)
  width: 384px
  z-index: 99999999999
  @media screen and (max-width: 768px)
    width: 100%
  .modal-popup
    position: absolute
    background-color: #ffffff
    width: 320px
    box-sizing: border-box
    border-radius: 5px
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    padding: 20px 30px
    @media screen and (max-width: 320px)
      width: 90%
    .modal-close
      position: absolute
      border: 0
      background-color: transparent
      top: 20px
      right: 20px
      cursor: pointer
    .modal-content
      margin-top: 20px
      .modal-title
        font-size: 18px
        letter-spacing: -0.5px
        font-weight: 700
        margin-bottom: 20px
        text-align: center
      .modal-text
        font-size: 14px
        letter-spacing: -0.5px
        line-height: 1.5
        text-align: center
        color: #54585a
    .modal-btn
      display: block
      font-family: "Noto Sans KR", sans-serif
      margin-top: 20px
      width: 100%
      height: 50px
      line-height: 50px
      text-align: center
      text-decoration: none
      border: 0
      border-radius: 5px
      font-size: 16px
      font-weight: 700
      cursor: pointer
      background-color: #3d3d57
      color: #ffffff


