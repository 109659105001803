.main-view-wrap
  width: 384px
  position: relative

  .chat-button
    display: flex
    justify-content: center
    align-items: center
    background-color: #f5f6f8
    button
      display: flex
      align-items: center
      background-color: #f7e600
      border: none
      border-radius: 20px
      padding: 10px 20px
      cursor: pointer
      transition: background-color 0.3s ease

      &:hover
        background-color: #e5d400

      img
        width: 24px
        height: 24px
        margin-right: 10px

      span
        font-size: 14px
        font-weight: bold
        color: #3c1e1e

    // 모달 스타일 추가 시작
  .modal
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center
    z-index: 1000

  .modal-content
    background-color: #fff
    padding: 20px
    border-radius: 5px
    max-width: 768px
    width: 100%
    position: relative
    max-height: 80vh  
    overflow-y: auto 

    .close
      position: absolute
      top: 10px
      right: 10px
      cursor: pointer
      font-size: 18px
    
    .modal-body
      margin-top: 40px  
    pre
      white-space: pre-wrap  
      word-wrap: break-word  
      overflow-wrap: break-word  
      width: 100%  

  @media screen and (max-width: 768px)
    width: 100%
  .popular-product-area
    position: relative
    z-index: 999
    background-color: #ffffff
    padding: 30px 20px
    .popular-product-header
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 10px
      h2
        font-size: 14px
      .more-btn
        font-size: 12px
        background-color: transparent
        border: 0
        cursor: pointer
        color: #8a8a8a
        font-weight: 600
        text-decoration: none
  .popular-product-list
    width: 100%
    box-sizing: border-box
    position: relative
    overflow: hidden
    .slider-container
      position: relative
      .item
        position: relative
        display: inline-block
        width: 134px
        margin: 5px
        cursor: pointer
        .item-thumbnail
          width: 134px
          height: 134px
          background-size: cover

.item-info
  .item-title
    font-size: 12px
    margin: 7px 0
  .item-size
    font-size: 10px
    margin-bottom: 5px
  .item-sep-line
    width: 100%
    height: 1px
    background-color: #dbdbdb
    margin-bottom: 5px
  .item-price
    font-size: 12px
    font-weight: 600
    color: #e0683a
    margin-bottom: 7px
  .item-review-like
    display: flex
    justify-content: space-between
    margin-bottom: 7px
    .item-reviews
      span:first-child
        font-weight: 800
        margin-right: 3px
    .item-likes
      span:last-child
        color: #ccc
        margin-left: 3px
    span
      font-size: 10px
  .item-badge
    span
      display: inline-block
      font-size: 10px
      font-weight: 800
      padding: 5px 5px
      border-radius: 10px
      margin-right: 2px
      &.size-changeable
        color: #3c3c55
        background-color: #f0f0f4
      &.color-changeable
        color: #e0683a
        background-color: #fcf0eb
      &.texture-changeable
        color: #3d3d57
        background-color: #d7f0ff
      background-color: #ffaabb

.category-list-area
  position: relative
  z-index: 999
  background-color: #f5f6f8
  padding: 30px 20px
  h2
    font-size: 14px
    margin-bottom: 20px
  .category-list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 10px
    .category-btn
      background-color: #fff
      text-align: center
      padding: 15px 0
      border-radius: 10px
      .category-title
        font-size: 12px
        font-weight: 600

.main-view-banner-area
  position: sticky
  top: 0
  width: 100%
  overflow: hidden
  height: 400px
  video, .banner-img
    width: 100%
    height: 100%
    object-fit: cover
  .main-view-video-cover
    position: absolute
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.6)
    z-index: 999
    top: 0
    .main-view-video-cover-inner
      color: #fff
      position: absolute
      bottom: 0
      left: 0
      padding: 20px
      h1
        width: 150px
        line-height: 1.5
        word-break: keep-all
        font-size: 24px
        font-weight: 600
        margin-bottom: 10px
      p
        font-size: 14px
        font-weight: 300
        width: 200px
        word-break: keep-all
        line-height: 1.5
      .main-view-btn
        display: inline-block
        margin-top: 30px
        margin-bottom: 20px
        color: #fff
        text-decoration: none
        font-size: 13px
        img
          position: relative
          margin-left: 5px
          top: -2px
 


.footer
  background-color: #f5f5f5
  padding: 20px
  font-family: Arial, sans-serif
  color: #333
  font-size: 12px
  box-sizing: border-box  
  width: 100%  

  &__info
    a
    color: #333
    text-decoration: none
    

    &:hover
      text-decoration: underline

  &__details
    padding-top: 5px
    pre
      width: 100%  
      white-space: pre-wrap  
      word-wrap: break-word  
      overflow-wrap: break-word  
  &__copyright
    font-weight: bold
    padding-top: 5px
    text-align: center  


.advertise-area
  display: none
  *
    width: 99%
  @media screen and (max-width: 766px)
    display: block