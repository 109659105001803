.member-info-view-wrap
  position: relative
  overflow: hidden
.member-info-view
  padding: 62px 20px 20px 20px
  box-sizing: border-box
  height: calc(var(--vh, 1vh) * 100)
  background-color: #ffffff

  h1
    margin-top: 25px
    margin-bottom: 25px
    font-size: 24px
    font-weight: 700
    color: #000000

.member-info-row
  margin-top: 20px
  position: relative
  p
    font-size: 14px
    font-weight: 700
    color: #949494
    margin-bottom: 5px
    &.name-error, &.email-error
      position: absolute
      right: 0
      top: 0
      font-size: 12px
      font-weight: 400
      color: #e94439
  input
    width: 100%
    outline: none
    height: 50px
    border: 1px solid #dbdbdb
    font-family: 'Noto Sans KR', sans-serif
    border-radius: 5px
    padding: 0 10px
    box-sizing: border-box
    font-size: 16px
    color: #000000
    &.error
      border-color: #e94439
    &:disabled
      color: #949494
      background-color: #F5F6F8

.change-password-btn, .member-info-change-accept
  border: 0
  font-family: "Noto Sans KR", sans-serif
  font-size: 16px
  padding: 0
  margin-top: 20px
  display: block
  width: 100%
  height: 50px
  border-radius: 5px
  line-height: 50px
  text-align: center
  text-decoration: none
  background-color: #F5F6F8
  color: #54585A
  font-weight: 700
  &.member-info-change-accept
    outline: none
    background-color: #3D3D57
    color: #ffffff
