.payment-success-content
  display: flex
  flex-direction: column
  align-items: center
  text-align: center  
  justify-content: center
  height: 100vh
  background-color: #fff

  .icon
    width: 100px
    height: 100px
    background: url('../images/sait-module-loading.png') no-repeat center center
    background-size: contain
    margin-bottom: 20px

  h1
    font-size: 24px
    font-weight: bold
    margin-bottom: 10px

  p
    font-size: 16px
    line-height: 1.5
    margin-bottom: 30px

  button
    padding: 15px 30px
    font-size: 16px
    color: #fff
    background-color: #3D3D57
    border: none
    border-radius: 5px
    cursor: pointer

    &:hover
      background-color: #2C2C3A
