.order-detail-view-wrap 
  width: 384px
  position: relative

  .module-assemble-3d-area 
    margin-bottom: 20px

  .order-item-info 
    width: 100%
    background: #fff
    border-radius: 8px
    padding-top : 60px
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)

    h1 
      font-size: 24px
      font-weight: bold
      color: #333
      padding : 10px


    .order-info-summary 
      div 
        font-size: 16px
        color: #666
        padding:10px

        strong 
          color: #333

    .order-item-list 
      .order-item 
        display: flex
        align-items: center
        border-bottom: 1px solid #e0e0e0
        padding: 10px

        .order-item-body 
          display: flex
          align-items: center
          width: 100%

          .order-item-img 
            width: 100px
            height: 100px
            background-size: cover
            background-position: center
            margin-right: 20px
            border-radius: 10%

          .order-item-detail 
            flex: 1
            .order-item-title 
              font-size: 18px
              font-weight: bold
              color: #333
              margin-bottom : 10px

            .order-item-price, .order-item-count
              font-size: 16px
              color: #666
              padding-bottom: 5px

            .order-item-price-sum 
              display: flex
              justify-content: space-between
              align-items: center
              width: 100%

            .review-button
              background-color: transparent
              border: 1px solid #666
              padding: 5px 10px
              cursor: pointer
              &:hover
                background-color: #f0f0f0

    .order-total-price 
      font-size: 18px
      font-weight: bold
      color: #333
      padding:10px

    .order-status 
      font-size: 16px
      color: red
      margin-top: 10px

    .shipping-info 
      padding : 10px
      color: #666
      
      strong 
        color: #333
      
      h1 
        font-size: 18px
        font-weight: bold
        color: #333
        margin-bottom: 10px

      .address-content 
        font-size: 16px
        margin-bottom: 10px

      .contact-info 
        margin-top: 10px

        h1 
          font-size: 18px
          font-weight: bold
          color: #333
          margin-bottom: 5px

        div 
          font-size: 16px
          color: #666

    .order-view-btn-area 
      padding : 10px
      
      .order-action-btn 
        width: 100%
        padding: 10px 0
        background-color: #ff6b6b
        border: none
        color: white
        font-size: 16px
        cursor: pointer
        transition: background-color 0.3s

        &:hover 
          background-color: #e65a50
