.main-view-top
  display: flex
  justify-content: space-between
  align-items: center
  padding: 20px
  position: fixed
  width: 384px
  box-sizing: border-box
  z-index: 99999
  transition: 0.15s
  @media screen and (max-width: 768px)
    width: 100%
  img
    transition: 0.1s
    filter: brightness(100)
  .menu-btn
    background-color: transparent
    border: 0
    cursor: pointer
    .menu-btn-line
      transition: 0.1s
      display: block
      width: 20px
      height: 1px
      background-color: #949494
      margin-bottom: 6px
      &:last-child
        margin-bottom: 0
    &.close-btn
      transform: translateX(5px)
      .menu-btn-line
        &:first-child
          transform: rotate(45deg) translateY(5px)
        &:nth-child(2)
          display: none
        &:last-child
          transform: rotate(-45deg) translateY(-5px)
  &.floating
    background-color: #fff
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
    img
      filter: none
    .menu-btn
      .menu-btn-line
        background-color: #3d3d57
  &.white-theme
    background-color: #fff
    img
      filter: none
    .menu-btn
      .menu-btn-line
        background-color: #3d3d57
  &.blue
    background-color: #3d3d57
    img
      filter: brightness(100)
    .menu-btn
      .menu-btn-line
        background-color: #fff
  &.gray
    background-color: #e0e0e0
    img
      filter: none
    .menu-btn
      .menu-btn-line
        background-color: #949494