.app
  display: flex
  flex-direction: row
  max-width: 768px
  margin: 0 auto
  @media screen and (max-width: 768px)
    display: block
  .left-side, .right-side
    flex: 1
    flex-basis: 384px
    flex-shrink: 0
  .left-side
    position: fixed
    z-index: 1
    @media screen and (max-width: 768px)
      display: none
  .right-side
    position: relative
    overflow: hidden
    padding-left: 384px
    @media screen and (max-width: 768px)
      padding-left: 0
      flex: 1
      flex-basis: 100%
      flex-shrink: 0
      position: relative