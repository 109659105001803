.login-view-wrap
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 100%
  background-color: #ffffff
  text-align: center
  height: 100vh
  .sait-main-logo
    width: 192px
    margin-bottom: 50px
  .login-form
    width: 100%
    box-sizing: border-box
    text-align: left
    padding: 20px
    .input-row
      position: relative
      &:first-of-type
        margin-bottom: 20px
      .err-msg
        position: absolute
        font-size: 12px
        color: #E94439
        font-weight: 400
        right: 0
        top: 0
    p
      font-size: 14px
      color: #949494
      font-weight: 700
      margin-bottom: 5px
    input
      width: 100%
      font-size: 16px
      font-family: "Noto Sans KR", sans-serif
      font-weight: 400
      height: 50px
      padding: 0 10px
      box-sizing: border-box
      border-radius: 5px
      border: 1px solid #dbdbdb
      &.error
        border-color: #E94439
  .login-btn-area
    width: 100%
    box-sizing: border-box
    padding: 20px
    button, a
      display: block
      line-height: 50px
      text-decoration: none
      width: 100%
      height: 50px
      font-family: "Noto Sans KR", sans-serif
      font-size: 16px
      font-weight: 700
      border: none
      border-radius: 5px
      cursor: pointer
      margin-bottom: 10px
      &:first-of-type
        background-color: #3d3d57
        color: #ffffff
      &:last-of-type
        background-color: #f5f6f8
        color: #54585a
  .find-id-pw
    width: 100%
    box-sizing: border-box
    padding: 0 20px
    margin-bottom: 20px
    display: flex
    justify-content: space-around
    .find-btn
      flex: 1
      font-size: 14px
      color: #000000
      text-decoration: none
      &:first-child
        border-right: 1px solid #dbdbdb
  .sns-login
    width: 100%
    box-sizing: border-box
    padding: 20px
    .sns-login-title
      margin-bottom: 20px
      display: flex
      .line
        flex: 1
        height: 1px
        background-color: #dbdbdb
        margin: 10px 0
      .text
        flex: 1
        flex-shrink: 0
        white-space: nowrap
        font-size: 14px
        color: #949494
        font-weight: 400
        padding: 0 20px
    .sns-login-btn-area
      display: flex
      justify-content: center
      align-items: center
      button
        background-color: transparent
        border: 0
        cursor: pointer
        img
          width: 54px

