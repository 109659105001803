.member-join-view-wrap
  position: relative
  width: 100%
  .step-indicator
    position: absolute
    right: 20px
    top: 82px
    background-color: #f0f0f4
    font-weight: 700
    padding: 10px 16px
    border-radius: 50px
    z-index: 99

.step-identitiy-verification
  padding: 20px
  padding-top: 82px
  height: calc(var(--vh, 1vh) * 100)
  background-color: #fff
  box-sizing: border-box
  h1
    font-size: 24px
    font-weight: 700
    margin-bottom: 30px
    line-height: 1.5
  .name-input-area
    position: relative
    margin-bottom: 20px
    p
      font-size: 14px
      color: #949494
      font-weight: 700
      margin-bottom: 5px
      &.name-error
        font-size: 12px
        color: #E94439
        font-weight: 400
        position: absolute
        top: 0
        right: 0
    input
      width: 100%
      outline: none
      height: 50px
      padding: 0 16px
      border: 1px solid #dbdbdb
      border-radius: 5px
      box-sizing: border-box
      font-size: 16px
      font-weight: 400
      margin-bottom: 10px
      font-family: 'Noto Sans KR', sans-serif
      &.error
        border-color: #E94439

.phone-verification-area
  p
    font-size: 14px
    color: #949494
    font-weight: 700
    margin-bottom: 5px
    &.error-msg
      color: #E94439
      font-size: 12px
      font-weight: 400
  .row
    display: flex
    margin-bottom: 10px
    div
      flex: 1
      display: flex
      height: 50px
      border: 1px solid #dbdbdb
      border-radius: 5px
      align-items: center
      box-sizing: border-box
      position: relative
      &.disabled
        color: #949494
        background-color: #f5f6f8
      &.error
        border-color: #E94439
      .verification-time
        position: absolute
        right: 16px
        top: 50%
        transform: translateY(-50%)
    input
      width: calc(100% - 16px)
      left: 16px
      position: relative
      border: 0
      height: 100%
      outline: none
      background-color: transparent
      font-family: "Noto Sans KR", sans-serif
      font-size: 16px

    button
      width: 105px
      flex-shrink: 0
      margin-left: 10px
      height: 50px
      border: 0
      border-radius: 5px
      font-family: "Noto Sans KR", sans-serif
      font-size: 16px
      font-weight: 700
      letter-spacing: -0.5px
      color: #3d3d57
      background-color: #f5f6f8
      &:disabled
        color: #949494

.next-btn-area
  position: absolute
  bottom: 20px
  left: 20px
  width: calc(100% - 40px)
  button
    width: 100%
    height: 50px
    border: 0
    border-radius: 5px
    font-family: "Noto Sans KR", sans-serif
    font-size: 16px
    font-weight: 700
    letter-spacing: -0.5px
    color: #fff
    background-color: #3d3d57
    &:disabled
      background-color: #dbdbdb

.step-account-info
  padding: 20px
  padding-top: 82px
  width: 100%
  position: relative
  box-sizing: border-box
  background-color: #ffffff
  min-height: calc(var(--vh, 1vh) * 100)
  h1
    font-size: 24px
    font-weight: 700
    margin-bottom: 30px
    line-height: 1.5
  .email-input-area, .password-input-area
    margin-bottom: 20px
    position: relative
    p
      font-size: 14px
      color: #949494
      font-weight: 700
      margin-bottom: 5px
      &.email-error, &.password-verification-error
        font-size: 12px
        color: #E94439
        font-weight: 400
        position: absolute
        top: 0
        right: 0
    input
      width: 100%
      outline: none
      height: 50px
      padding: 0 16px
      border: 1px solid #dbdbdb
      border-radius: 5px
      box-sizing: border-box
      font-size: 16px
      font-weight: 400
      margin-bottom: 10px
      font-family: 'Noto Sans KR', sans-serif
      &.error
        border-color: #E94439

.terms-area
  width: 100%
  @media screen and (min-height: 761px)
    position: absolute
    bottom: 0px
    left: 0px
    padding: 20px
    box-sizing: border-box
  .row
    display: flex
    align-items: center
    justify-content: space-between
    padding: 10px 15px
    &.all-agree
      padding: 15px
      margin-bottom: 10px
      font-weight: 700
      background-color: #f5f6f8
      border-radius: 5px
    .agree-check-box
      display: none
      &:checked+label
        border-color: #3d3d57
        background-color: #3d3d57
    .check-box-btn-label
      display: inline-block
      box-sizing: border-box
      border: 1px solid #dbdbdb
      background-color: #fff
      background-position: center
      background-repeat: no-repeat
      width: 20px
      height: 20px
      background-size: 12px
      border-radius: 50%
      user-select: none
      cursor: pointer
    .check-box-content-label
      flex: 1
      white-space: nowrap
      user-select: none
      font-size: 13px
      cursor: pointer
      line-height: 20px
      margin-left: 10px
    a
      color: #54585a
      font-size: 12px
      font-weight: 400
      text-decoration: underline
      line-height: 20px
      white-space: nowrap

.join-btn-area
  margin-top: 30px
  button
    width: 100%
    height: 50px
    border: 0
    border-radius: 5px
    font-family: "Noto Sans KR", sans-serif
    font-size: 16px
    font-weight: 700
    letter-spacing: -0.5px
    color: #fff
    background-color: #3d3d57
    &:disabled
      background-color: #dbdbdb

.step-complete
  display: flex
  padding: 20px
  padding-top: 180px
  flex-direction: column
  align-items: center
  box-sizing: border-box
  background-color: #ffffff
  min-height: calc(var(--vh, 1vh) * 100)
  img
    width: 192px
    margin-bottom: 30px
  h1
    font-size: 24px
    letter-spacing: -0.5px
    margin-bottom: 20px
  p
    font-size: 14px
    line-height: 1.5
    color: #5f5f5f
  .link-area
    margin-top: 50px
    width: 100%
    .step-complete-link
      display: block
      text-align: center
      line-height: 50px
      width: 100%
      height: 50px
      margin-bottom: 10px
      font-size: 14px
      font-weight: 700
      text-decoration: none
      border-radius: 5px
      background-color: #3D3D57
      color: #ffffff
      &:last-child
        background-color: #f5f6f8
        color: #54585A
