.main-banner-view-wrap
  padding: 10px
  padding-top: 0px
  background-color: #f0f0f4
.main-banner
  margin-top: 0px
  width: 360px
  margin-bottom: 10px
  .banner-content
    position: relative
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    border-radius: 5px
    overflow: hidden
    height: 492px
    .button-area
      position: absolute
      bottom: 30px
      left: 20px
  .slick-dots
    position: absolute
    bottom: 20px
    right: 10px
    width: auto
    li
      margin: 0px
      &.slick-active
        button
          background-color: #ffffff
      button
        background-color: rgba(255,255,255,0.5)
        width: 8px
        height: 8px
        padding: 0
        border-radius: 50%
        &::before
          display: none

.banner-content
  h2
    color: #ffffff
    font-size: 22px
    font-weight: 600
    width: 180px
    margin-left: 20px
    padding-top: 20px
    line-height: 1.5
    word-break: keep-all
  p
    font-weight: 300
    font-size: 14px
    color: #ffffff
    line-height: 1.5
    margin-left: 20px
    margin-top: 10px
    width: 200px
    word-break: keep-all

.banner-btn
  box-sizing: border-box
  display: inline-block
  padding: 10px 15px
  width: 155px
  border: 1px solid #fff
  > img, span
    color: #ffffff
    font-size: 12px
    font-weight: 500
    line-height: 12px
    display: inline-block
    vertical-align: middle
    position: relative
    top: -2px
  > img
    margin-left: 15px
    top: -4px
  &:hover
    background-color: #ffffff
    > span
      color: #000
    > img
      filter: invert(1)

.main-button-area
  width: 360px
  .main-btn
    border-radius: 5px
    display: flex
    width: 100%
    background-color: #ffffff
    align-items: center
    justify-content: space-between
    box-sizing: border-box
    padding: 20px 25px
    text-decoration: none
    margin-top: 15px
    color: #3d3d57
    h2
      font-size: 18px
      margin-bottom: 15px
      font-weight: 600
    p
      font-size: 13px
      font-weight: 400
    &.current
      background-color: #3d3d57
      h2
        color: #ffffff
      p
        color: #ffffff
      img
        filter: brightness(100)

  .small-banner-item
    margin-bottom: 10px
    img
      border-radius: 5px
      width: 100%
      height: 100%






