.product-detail-wrap
  width: 384px
  position: relative
  @media screen and (max-width: 768px)
    width: 100%

.product-detail-content
  padding-top: 62px
  background-color: #ffffff
  .product-image
    position: relative
    width: 100%
    padding-bottom: 100%
    background-position: center
    background-repeat: no-repeat
    background-size: cover

.product-basic-info
  padding: 20px
  .product-title
    font-size: 20px
    font-weight: 700
    margin-bottom: 5px
  .product-specs
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 2px solid #dbdbdb
    padding-bottom: 10px
    margin-bottom: 10px
    .spec-size
      font-size: 14px
      color: #878787
    .spec-changeable
      span
        display: inline-block
        font-size: 10px
        font-weight: 800
        padding: 5px 5px
        border-radius: 10px
        margin-right: 2px
        &.size-changeable
          color: #3c3c55
          background-color: #f0f0f4
        &.color-changeable
          color: #e0683a
          background-color: #fcf0eb
        &.texture-changeable
          color: #3d3d57
          background-color: #d7f0ff
  .product-price
    font-size: 18px
    font-weight: 700
    color: #e0683a
    margin-bottom: 10px
  .product-review-and-like-count
    .review-cnt
      margin-right: 30px
      b
        margin-right: 3px
      span
        font-weight: 400
        color: #858585
    .wishlist-cnt
      span
        img
          position: relative
          top: 3px
          cursor: pointer
        &:first-child
          color: #dbdbdb
          margin-right: 5px
        &:last-child
          font-weight: 400
          color: #858585
.product-purchase-btns
  padding: 0 20px
  display: flex
  margin-bottom: 10px
  button
    height: 50px
    border-radius: 5px
    border: 0
    font-weight: 700
    outline: none
    cursor: pointer
    font-family: "Noto Sans KR", sans-serif
    &.purchase
      flex: 2
      background-color: #3D3D57
      color: #ffffff
    &.add-to-cart
      flex: 1
      margin-left: 10px
      background-color: #F5F6F8
      color: #54585A

.product-detail
  padding: 20px
  img
    width: 100%
    margin-bottom: 20px
  b, h1
    display: block
    margin-bottom: 10px
  p
    font-size: 14px
    line-height: 1.5

.product-review-area
  margin-top: 8px
  padding: 20px
  background-color: #fff
  .review-header
    display: flex
    justify-content: space-between
    align-items: center
  h1
    font-size: 20px
    font-weight: 700
.product-review-item
  padding: 20px 0
  .review-img-wrap
    display: flex
    height: 200px
    align-items: center
    margin-bottom: 10px
    .review-img
      flex: 1
      height: 100%
      background-position: center
      background-size: cover
  h2
    font-size: 16px
    font-weight: 700
    margin-bottom: 10px
  p
    font-size: 13px
    line-height: 1.5
    margin-bottom: 20px
  h3
    font-size: 12px

.all-reviews-link
  text-align: center
  margin-top: 20px

.all-reviews-button
  display: inline-block
  padding: 10px 10px
  background-color: #3D3D57
  color: #fff
  text-decoration: none
  border-radius: 5px
  font-size: 14px
  transition: background-color 0.3s
